import CaseEditPanel from "~/javascript/cases/case_panels/case_edit_panel";

export default class EditAssignmentForm {
  constructor({ assignmentId }) {
    this.assignmentId = assignmentId;
  }

  bindForm() {
    const editPanel = new CaseEditPanel({
      areaSelector: '#assignments_area',
      errorSelector: `#edit_assignment_${this.assignmentId}_errors`,
      deleteButtonSelector: `#delete_assignment_${this.assignmentId}_button`,
      formSelector: `#edit_assignment_${this.assignmentId}_form`,
      objectId: this.assignmentId
    });
    editPanel.bindForm();
  }
}

