import { Controller } from 'stimulus'

// some fields don't have matching names, this allows us to set them.
const requiredRemaps = {
  'provider_supervisor': 'supervisor',
  'schedule_drug_confirmation': 'drug_reconciliation',
}

const notUsedRemaps = {
  'drug_reconciliation': 'schedule_drug_confirmation',
  'supervisor': 'provider_supervisor',
}

const notUsedItemToRequiredItemsMapping = {
  'height:weight': ['height', 'weight']
}

class LocationsRequiredController extends Controller {
  static targets = ['required', 'notUsed']

  connect() {
    this.requiredTargets.forEach(target => {
      if(target.checked) {
        var field = this.fieldName(target.dataset.attr, requiredRemaps)
        this.toggle(field, this.notUsedTargets)
      }
    })

    this.notUsedTargets.forEach(target => {
      if(target.checked) {
        var field = this.fieldName(target.dataset.attr, notUsedRemaps)
        this.toggle(field, this.requiredTargets)
      }
    })

    Object.keys(notUsedItemToRequiredItemsMapping).forEach(notUsedGroup => {
      if (this.findNotUsedElement(notUsedGroup).checked) {
        notUsedItemToRequiredItemsMapping[notUsedGroup].forEach(requiredField => {
          this.findRequiredElement(requiredField).disabled = true;
        })
      } else {
        this.checkNotUsedGroupedToggleState(notUsedGroup);
      }
    })
  }

  toggleNotUsed(e) {
    let attr = e.target.dataset.attr;
    if (attr in notUsedItemToRequiredItemsMapping) {
      notUsedItemToRequiredItemsMapping[attr].forEach(field => {
        this.toggle(field, this.requiredTargets);
      });
    } else {
      let field = this.fieldName(e.target.dataset.attr, notUsedRemaps);
      this.toggle(field, this.requiredTargets);
    }
  }

  toggleRequired(e) {
    let attr = e.target.dataset.attr;
    let group = this.itemGroup(attr, notUsedItemToRequiredItemsMapping);

    if (group) {
      this.checkNotUsedGroupedToggleState(group);
    } else {
      let field = this.fieldName(e.target.dataset.attr, requiredRemaps);
      this.toggle(field, this.notUsedTargets);
    }
  }

  checkNotUsedGroupedToggleState(group) {
    let fields = notUsedItemToRequiredItemsMapping[group];
    if (fields.some(field => this.isRequiredChecked(field))) {
      this.findNotUsedElement(group).disabled = true;
    } else if (fields.every(field => !this.isRequiredChecked(field))) {
      this.findNotUsedElement(group).disabled = false;
    }
  }

  itemGroup(field, set) {
    return Object.keys(set).find(group => set[group].includes(field));
  }

  fieldName(field, remaps) {
    return ((remaps[field] == undefined) ? field : remaps[field])
  }

  findNotUsedElement(field) {
    return document.querySelector(`.not_used_data input[data-attr="${field}"]`);
  }

  findRequiredElement(field) {
    return document.querySelector(`.required_data input[data-attr="${field}"]`);
  }

  isRequiredChecked(field) {
    return this.findRequiredElement(field).checked;
  }

  toggle(field, targets) {
    targets.forEach(target => {
      if(target.dataset.attr == field) {
        target.toggleAttribute('disabled')
      }
    })
  }
}
export default LocationsRequiredController
