import { Controller } from 'stimulus'

class ImageLabelExecutableController extends Controller {
  static targets = [
    'imageLabel', 'imageRequired', 'executableFormId', 'executableFormRequired'
  ];

  imageLabelTargetConnected() {
    this._imageRequiredTarget = this.imageRequiredTarget;
    this._imageLabelTarget = this.imageLabelTarget;
    this.imageLabelChanged();
  }

  executableFormIdTargetConnected() {
    this._executableFormIdTarget = this.executableFormIdTarget;
    this._executableFormRequiredTarget = this.executableFormRequiredTarget;
    this.executableFormIdChanged();
  }

  imageLabelChanged() {
    if (this.imageLabelId) {
      this.enable(this._imageRequiredTarget);
      this.disable(this._executableFormIdTarget);
    } else {
      this.disable(this._imageRequiredTarget);
      this.enable(this._executableFormIdTarget);
    }
  }

  executableFormIdChanged() {
    if (this.executableFormId) {
      this.enable(this._executableFormRequiredTarget);
      this.disable(this._imageLabelTarget);
    } else {
      this.disable(this._executableFormRequiredTarget);
      this.enable(this._imageLabelTarget);
    }
  }

  enable(element) {
    if (element) {
      element.disabled = false;
    }
  }

  disable(element) {
    if (!element) {
      return;
    }
    if (element.type && element.type == 'checkbox') {
      element.checked = false;
    }
    element.disabled = true;
  }

  get imageLabelId() {
    if (this.hasImageLabelTarget) {
      return this.imageLabelTarget.value || null;
    } else {
      return null;
    }
  }

  get executableFormId() {
    if (this.hasExecutableFormIdTarget) {
      return this.executableFormIdTarget.value || null;
    } else {
      return null;
    }
  }

}
export default ImageLabelExecutableController