import date_time_picker from '~/javascript/date_time_picker';

export default class FunctionalStatusForm {
  constructor({ mode, functionalStatusId }) {
    this.mode = mode;
    this.functionalStatusId = functionalStatusId;
  }

  bindForm() {
    this.bindDateFields();
  }

  bindDateFields() {
    const startDate = $j(this.formId).find('.start_date_wrapper');

    date_time_picker(startDate, {
      defaultDate: new Date()
    });

    const endDate = $j(this.formId).find('.end_date_wrapper');
    date_time_picker(endDate);
  }

  get formId() {
    if(this.mode === 'add') {
      return "#add_functional_status_form";
    } else {
      return `#edit_functional_status_${this.functionalStatusId}_form`;
    }
  }
}
