import { Controller } from 'stimulus'

class LocationsEditController extends Controller {
  static targets = [
    'appendedFormInactive', 
    'operatingRoomsInactive',
    'assistRoleInactive',
    'printerSetInactive',
    'surgeonInactive',
    'surgeonCheckbox',
    'appendedFormCheckbox',
    'operatingRoomCheckbox',
    'assistRolesCheckbox',
    'printerSetsCheckbox',
  ]
  initialize() {}
  connect() {
    // see if we should default inactive checkboxes to checked.
    var surgeonsChecked = sessionStorage.getItem('surgeons_visible')
    if(surgeonsChecked == 1) {
      this.surgeonCheckboxTarget.checked = true
      this.toggleSurgeons()
    }

    var appendedFormChecked = sessionStorage.getItem('appended_forms_visible')
    if(appendedFormChecked == 1) {
      this.appendedFormCheckboxTarget.checked = true
      this.toggleAppendedForm()
    }

    var operatingRoomsChecked = sessionStorage.getItem('operating_rooms_visible')
    if(operatingRoomsChecked == 1) {
      this.operatingRoomCheckboxTarget.checked = true
      this.toggleOperatingRoom()
    }

    var assistRolesChecked = sessionStorage.getItem('assist_roles_visible')
    if(assistRolesChecked == 1) {
      this.assistRolesCheckboxTarget.checked = true
      this.toggleAssistRoles()
    }

    var printerSetsChecked = sessionStorage.getItem('printer_sets_visible')
    if(printerSetsChecked == 1) {
      this.printerSetsCheckboxTarget.checked = true
      this.togglePrinterSets()
    }
  }

  toggleAppendedForm() {
    this.toggleTargets(
      this.appendedFormInactiveTargets,
      this.appendedFormCheckboxTarget,
      'appended_forms_visible'
    )
  }

  toggleOperatingRoom() {
    this.toggleTargets(
      this.operatingRoomsInactiveTargets,
      this.operatingRoomCheckboxTarget,
      'operating_rooms_visible'
    )
  }

  toggleAssistRoles() {
    this.toggleTargets(
      this.assistRoleInactiveTargets,
      this.assistRolesCheckboxTarget,
      'assist_roles_visible'
    )
  }

  toggleSurgeons() {
    this.toggleTargets(
      this.surgeonInactiveTargets,
      this.surgeonCheckboxTarget,
      'surgeons_visible'
    )
  }

  togglePrinterSets() {
    this.toggleTargets(
      this.printerSetInactiveTargets,
      this.printerSetsCheckboxTarget,
      'printer_sets_visible'
    )
  }

  toggleTargets(targets, checkbox, storage) {
    if(checkbox.checked) {
      sessionStorage.setItem(storage, 1)
      targets.forEach(target => {
        target.classList.remove('hidden')
      })
    } else {
      targets.forEach(target => {
        sessionStorage.setItem(storage, 0)
        target.classList.add('hidden')
      })
    }
  }
}
export default LocationsEditController
