import { Controller } from 'stimulus'

class ItemTypesCopiesCreateController extends Controller {
  static targets = [
    'applyButton',
    'destinationLocationSelect',
    'itemTypeCheckBox',
    'sourceLocationSelect'
  ]

  initialize() { }
  connect() {
    this.formFrame = document.getElementById('item-types-copy-frame');
  }

  reloadForm(event) {
    const sourcePracticeId = event.target.value;
    this.formFrame.src =
      `/item_types/copies/new.html?source_practice_id=${sourcePracticeId}`;
  }

  enableOrDisableApplyButton(_event) {
    if (this.isValidForm()) {
      this.applyButtonTarget.removeAttribute('disabled');
      this.applyButtonTarget.classList.remove('disabled');
    } else {
      this.applyButtonTarget.setAttribute('disabled', 'disabled');
      this.applyButtonTarget.classList.add('disabled');
    }
  }

  isValidForm() {
    return this.sourceLocationSelectTarget.value.length > 0 &&
             this.destinationLocationSelectTarget.value.length > 0 &&
               this.itemTypeCheckBoxTargets.some(el => el.checked);
  }
}
export default ItemTypesCopiesCreateController;
