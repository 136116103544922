import { Controller } from 'stimulus'

class QuickSetsController extends Controller {
  static targets = [
    'categorySelector',
    'quickSetType',
    'categoryLabel',
    'submit',
    'categoryContainer',
    'noCategoryLabel'
  ];

  get quickSetType() {
    if (this.hasQuickSetTypeTarget) {
      return this.quickSetTypeTarget.value;
    } else {
      return this.element.dataset.quickSetType;
    }
  }

  connect() {
    this.showSelectedType();
  }

  showSelectedType() {
    if (!this.hasCategoryContainerTarget) {
      return;
    }

    this.categorySelectorTargets.forEach((target) => {
      target.classList.add('hidden');
      target.disabled = true;
    });

    if (this.hasCategoryForType()) {
      this.enableCategorySelectors();
    } else {
      this.noCategoryLabelTarget.classList.remove('hidden');
    }
  }

  enableCategorySelectors() {
    this.noCategoryLabelTarget.classList.add('hidden');
    this.categoryLabelTarget.classList.remove('hidden');

    this.categorySelectorTargets.forEach((target) => {
      if (target.dataset.categoryType == this.quickSetType) {
        target.classList.remove('hidden');
        target.disabled = false;
      }
    });
  }

  hasCategoryForType() {
    let hasCategory = false;

    this.categorySelectorTargets.forEach((target) => {
      if (target.dataset.categoryType == this.quickSetType) {
        hasCategory = true;
      }
    });

    return hasCategory;
  }

  submitForm() {
    this.submitTarget.disabled = true;
    this.submitTarget.value = 'Saving...';
    this.element.submit();
  }
}
export default QuickSetsController