import CaseAddPanel from '~/javascript/cases/case_panels/case_add_panel';
import { bindBillingCodeSelect, bindModifierSelect } from '~/javascript/cases/billing_codes/billing_code_controls';

export default class AddBillingCodeForm {
  constructor({ billingCodeAutocompleteUrl, modifiersAutocompleteUrl }) {
    this.billingCodeAutocompleteUrl = billingCodeAutocompleteUrl;
    this.modifiersAutocompleteUrl = modifiersAutocompleteUrl;
  }

  bindForm() {
    const addPanel = new CaseAddPanel({
      areaSelector: '#billing_codes_area',
      errorSelector: '#add_billing_code_errors',
      formSelector: '#add_billing_code_form',
    });

    addPanel.bindForm();

    bindBillingCodeSelect({
      selector: "#add_billing_code_form #coded_value_code",
      autocompleteUrl: this.billingCodeAutocompleteUrl
    });

    bindModifierSelect({
      selector: "#coded_value_first_modifier_concept",
      autocompleteUrl: this.modifiersAutocompleteUrl
    });

    bindModifierSelect({
      selector: "#coded_value_second_modifier_concept",
      autocompleteUrl: this.modifiersAutocompleteUrl
    });

  }
}
