import CasePanel from '~/javascript/case_panel';
import _ from 'underscore';
import { processResults } from '~/javascript/utilities/autocompleter';
import CptSelect from '~/javascript/cases/procedures/cpt_select';
import { addSaveShortcut } from '~/javascript/application_shortcuts';
export default class ProcedureForm {
  constructor({ mode, procedureId, modifiersAutoCompleteUrl }) {
    this.mode = mode;
    this.procedureId = procedureId;
    this.modifiersAutoCompleteUrl = modifiersAutoCompleteUrl;
  }

  bindForm() {
    this.bindCasePanel();
    this.bindCptSelect();
    this.bindModifierSelects();
    this.bindModifierAddButton();
  }

  bindCasePanel() {
    const baseId = this.mode === 'add' ? 'add_procedure' : `edit_procedure_${this.procedureId}`;
    const casePanel = new CasePanel('procedures_area', baseId);
    this.bindKeyboardEvents(casePanel);
  }

  bindCptSelect() {
    const input = $j(this.formSelector).find("select.cpt");
    new CptSelect(input);
  }

  bindModifierSelects() {
    this.bindModifierSelect(this.formSelector.find('select.modifier'));
  }

  bindModifierAddButton() {
    const context = this;
    this.formSelector.find('span.plus a').click(function(e) {
      e.preventDefault();
      const $dupe = $j(this).parents('div.modifier').clone(true);

      const $positionInput = $dupe.find('input');
      const newPosition = Number($positionInput.val()) + 1;
      $positionInput.val(newPosition);
      const newName = $positionInput.attr('name').replace(/\d+/, newPosition);
      $positionInput.attr('name', newName);
      const newId = $positionInput.attr('id').replace(/\d+/, newPosition);
      $positionInput.attr('id', newId);

      $dupe.find('.select2-container').remove();
      const $dupeSelect = $dupe.find('select');
      const newModifierSelectName = $dupeSelect.attr('name').replace(/\d+/, newPosition);
      const newModifierSelectId = $dupeSelect.attr('id').replace(/\d+/, newPosition);
      const $s = $j('<select>', {'class': 'modifier', name: newModifierSelectName, id: newModifierSelectId});
      $dupeSelect.replaceWith($s);
      context.bindModifierSelect($dupe.find('select'));

      $dupe.appendTo($j(this).parents('div#code_and_modifiers'));
      $j(this).parent('span.plus').remove();
    });
  }

  bindModifierSelect($select) {
    const resultsFormatter = (results) => {
      return _.map(results, result => {
        const { code, name} = result;
        const id = `[${code}] ${name}`
        const text = `${code} - ${name}`;

        return { id, text };
      });
    };

    $select.select2({
      width: 300,
      minimumInputLength: 1,
      placeholder: 'Select a modifier',
      allowClear: true,
      ajax: {
        url: this.modifiersAutoCompleteUrl,
        delay: 250,
        data: (params) => {
          return {
            code: params.term
          }
        },
        dataType: 'json',
        processResults: (data, params) => {
          return processResults(data, params, resultsFormatter)
        }
      }
    });
  }

  bindKeyboardEvents(casePanel) {
    addSaveShortcut(this.formSelector, e => { casePanel.submitForm(e) });
  }

  get formSelector() {
    if(this.mode === 'add') {
      return $j("#add_procedure_form");
    }

    return $j(`#edit_procedure_${this.procedureId}_form`);
  }
}
