import _ from 'underscore';

var AddPanel = {
  toggle:function(panelId) {
    var panel = $j(`#${panelId}`);
    panel.is(":visible") ? AddPanel.close(panel) : AddPanel.open(panel);
    return false;
  },
  close: function(panel) {
    panel.hide();
    var button = $j(`#${panel.attr('id')}_button`);
    button.html(button.data("original-text"));
    var form = panel.find('form')[0];
    if (form && form.length) {
      form.reset();
    }
    var errors = $j(`#${panel.attr('id')}_errors`);
    if (errors)
      errors.html('');
  },
  open: function(panel) {
    panel.show();
    var button = $j(`#${panel.attr('id')}_button`);
    button.data("original-text", button.html());
    button.html('Close &uarr;');
  }
};

var EditPanel = {
  toggle: function(panelId) {
    var panel = $j(`#${panelId}`);

    if (panel.is(":visible")) {
      this.close(panel);
    } else {
      // close any open edit panels
      _.each(panel.closest('table.selectable').find('.edit_panel'), p => {
        this.close($j(p));
      });

      this.open(panel);
    }
    return false;
  },
  close: function(panel) {
    panel.hide();
    var prevRow = panel.prev();
    prevRow.removeClass('edit_panel_open');
    var form = panel.find('form')[0]
    if (form && form.length > 0) {
      form.reset();
    }
    var errors = $j(`#${panel.attr('id')}_errors`);
    if (errors.length > 0) {
      errors.html('');
    }
  },
  open: function(panel) {
    panel.show();
    var prevRow = panel.prev();
    prevRow.addClass('edit_panel_open');
  }
};

export { AddPanel, EditPanel };