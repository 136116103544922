import { Controller } from 'stimulus'

class LocationsEncountersController extends Controller {
  static targets = [ 
    'enableRounds',
    'useGroupForRounding'
  ]

  connect() {
    this.toggleGroup()
  }

  toggleGroup() {
    if(this.enableRoundsTarget.checked) {
      this.useGroupForRoundingTarget.disabled = false
    } else {
      this.useGroupForRoundingTarget.disabled = true
    }
  }
}
export default LocationsEncountersController
