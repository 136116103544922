import { initializeRxSelect } from '~/javascript/rx_select';
import _ from 'underscore';

export default class AddEditMedForm {
  constructor(options) {
    this.mode = options.mode;
    this.drugAutoCompleteUrl = options.drugAutoCompleteUrl;
    this.currentMedId = options.currentMedId;
  }

  bindForm() {
    if(this.mode === 'add') {
      this.bindDrugSelect();
    }

    this.bindStartDateField();
    this.bindLastTakenField();
  }

  bindDrugSelect() {
    const resultsFormatter = (results) => {
      return _.map(results, (provider) => {
        const optionText = `${provider.value} - ${provider.rxcui}`;
        return { id: provider.id, text: optionText, drugName: provider.value };
      })
    };

    const drugNameField = $j(this.formId).find('select.drug-name');

    const drugSelect = initializeRxSelect({
      field: drugNameField,
      autocompleteUrl: this.drugAutoCompleteUrl,
      includeClasses: false,
      resultsFormatter
    });
  }

  bindStartDateField() {
    const wrapper = $j(this.formId).find('.start_date_wrapper');
    date_time_picker(wrapper);
  }

  bindLastTakenField() {
    const wrapper = $j(this.formId).find('.last_taken_wrapper');
    date_time_picker(wrapper);
  }

  get formId() {
    if(this.mode === 'add') {
      return '#add_current_med_form';
    } else {
      return  `#edit_current_med_${this.currentMedId}_form`;
    }
  }
}
