import date_time_picker from '~/javascript/date_time_picker';

export default class EditCaseInfo {
  bindForm() {
    this.bindSurgeons();
    this.bindDateOfBirth();
    this.bindAnesType();
  }

  bindSurgeons() {
    $j('#edit_surgeon .surgeon-select').select2({
      width: 200,
      placeholder: 'Select a surgeon',
      allowClear: true,
      tags: true
    });
  }

  bindDateOfBirth() {
    date_time_picker('#date_of_birth_wrapper', {
      maxDate: dateFns.endOfToday(),
    });
  }

  bindAnesType() {
    AnestheticSelect.change_1(null);
    $j('select#anes_select_1').on('change', AnestheticSelect.change_1);
    $j('select#anes_select_2').on('change', AnestheticSelect.change_2);
  }
}
