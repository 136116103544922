import { Controller } from 'stimulus';

class ModalController extends Controller {
  static targets = ['popup', 'overlay'];

  hideModal() {
    this.overlayTarget.style.display = 'none';
    this.popupTarget.style.display = 'none';
    this.element.dataset.showing = 'false';
  }

  showModal() {
    var data = this.element.dataset;
    var width = data.width;
    var height = data.height;
    this.element.dataset.showing = 'true';
    this.popupTarget.style.width = width;
    this.popupTarget.style.height = height;
    this.overlayTarget.style.display = 'block';
    this.popupTarget.style.display = 'block';
  }
}

export default ModalController;
