import { Controller } from 'stimulus'

const CLOSED_STATUSES = [
  'Closed',
  'Lost Contract'
]

class UsageDateController extends Controller {
  static targets = [
    'statusSelect',
    'usageStopContainer',
    'usageStartDateField',
    'usageStopDateField'
  ]

  connect() {
    window.usageStopDateValidation = (date) => {
      return this.usageStopDateValidation(date);
    }
    this.showHideUsageStop();
  }

  usageStopDateValidation(date) {
    let startDate = this.usageStartDateFieldTarget.value;
    return startDate && new Date(startDate) <= date;
  }

  usageStartChanged() {
    this.showHideUsageStop();
  }

  statusChanged() {
    if (!this.hasClosedStatus) {
      this.usageStopDateFieldTarget.value = '';
    }
    this.showHideUsageStop();
  }

  showHideUsageStop() {
    if (this.hasClosedStatus && this.hasStartDate) {
      this.usageStopContainerTarget.classList.remove('hidden');
    } else {
      this.usageStopDateFieldTarget.value = '';
      this.usageStopContainerTarget.classList.add('hidden');
    }
  }

  get startDate() {
    if (this.usageStartDateFieldTarget.value) {
      return new Date(this.usageStartDateFieldTarget.value);
    } else {
      return null;
    }
  }

  get stopDate() {
    if (this.usageStopDateFieldTarget.value) {
      return new Date(this.usageStopDateFieldTarget.value);
    } else {
      return null;
    }
  }

  get hasStartDate() {
    return this.usageStartDateFieldTarget.value;
  }

  get hasClosedStatus() {
    return CLOSED_STATUSES.some(
      status => this.statusSelectTarget.value == status
    );
  }
}
export default UsageDateController
