const AssistorFields = {
  toggle:function() {
    if ($j('#add_assignment_form').length > 0) {
      var assistor = ($j('#assignment_role').val() === 'Assistor');

      var assistorFields = $j('#add_assignment_form .assistor_fields');

      assistorFields.each((idx, el) => {
        (assistor) ? $j(el).show() : $j(el).hide();
      });

      var providerSpvrFields = $j('#add_assignment_form .provider_spvr_fields');
      providerSpvrFields.each((idx, el) => {
        (assistor) ? $j(el).hide() : $j(el).show();
      });
    }
  }
};

export default AssistorFields;