import { SelectableOption, Selectable } from '~/javascript/selectable';
import SelectableRow from '~/javascript/selectable_row';
import LocationTagSelect from '~/javascript/location_tag_select';
import AsaField from '~/javascript/asa_field';
import CaseInfoForm from '~/javascript/cases/case_info_form';
import ClinicalInformationReconciliation from '~/javascript/cases/clinical_information_reconciliation';
import { setCSRFHeaders } from '~/javascript/utilities/ajax_helpers';
import { render as renderClinicalCompletionInfo } from '~/javascript/cases/clinical_case_completion_info';

const bindCaseEvents = () => {
  $j(document).on('mouseover', '.edit_case .table.selectable .option', Selectable.mouseover);
  $j(document).on('mouseout', '.edit_case .table.selectable .option', Selectable.mouseout);

  $j(document).on('click', '.edit_case .table.selectable .option', SelectableOption.click);
  $j(document).on('change', 'form.new_case #case_location_tag_id', LocationTagSelect.change);
  $j(document).on('change', 'form.edit_case_info #case_asa', AsaField.change);
  $j(document).on('submit', 'form.edit_case_info', CaseInfoForm.submit);
  $j(document).on('click', '.clinical-information-reconcile-button', ClinicalInformationReconciliation.showOverlay);
  $j(document).on('click', '.clinical-information-reconciliation input[type=checkbox]', ClinicalInformationReconciliation.checkboxClicked);

  $j(document).on('change', 'input.none-field', e => {
    const form = e.target.form;
    const { action, method } = form;

    $j.ajax({
      beforeSend: setCSRFHeaders,
      url: action,
      method: method,
      data: $j(form).serialize(),
      success: (response) => {
        $j(form).closest('.association').html(response);
        renderClinicalCompletionInfo();
      }
    });
  });
};

// TODO: Ideally, these events should be moved into respective JS packs that only get
// loaded when certain pages need them. However, these are being moved from legacy
// code where we bound lots of various page events in the main application script.
// For safety, we're preserving that behavior by binding a set of global events
// that we can slowly migrate onto specific page packs over time.
export const bindApplicationEvents = () => {
  $j(() => {
    $j(document).on('mouseover', 'table.selectable tr', Selectable.mouseover);
    $j(document).on('mouseout', 'table.selectable tr', Selectable.mouseout);
    $j(document).on('click', 'table.selectable td', SelectableRow.click);

    bindCaseEvents();
  });
};
