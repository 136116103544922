const ArchiveSearch = {
  advanced: function(e) {
    this.toggle(e, 'advanced');
  },
  basic: function(e) {
    this.toggle(e, 'basic');
  },
  toggle: function(e, state) {
    e.preventDefault();

    const link = $j(e.target);
    const form_group = $j('div#advanced-group');

    if (state === 'basic') {
      form_group.find('input').val('');
    }
    form_group.toggleClass('hide-advanced', state === 'basic');
    link.siblings('.selected').removeClass('selected');
    link.addClass('selected');
  }
};

export default ArchiveSearch;
