import { Controller } from 'stimulus';

class UserGroupsController extends Controller {
  static targets = [
    'inactiveUserGroup',
    'inactiveUserGroupsCheckbox',
    'includeInPracticeCheckbox',
  ];

  connect() {
    const inactiveUserGroupsChecked = sessionStorage.getItem(
      'inactive_user_groups_visible',
    );
    if (inactiveUserGroupsChecked == 1) {
      this.inactiveUserGroupsCheckboxTarget.checked = true;
    }
    this.toggleHiddenRows();
  }

  toggleHiddenRows() {
    if (this.inactiveUserGroupsCheckboxTarget.checked) {
      sessionStorage.setItem('inactive_user_groups_visible', 1);
      this.inactiveUserGroupTargets.forEach((target) => {
        target.classList.remove('hidden');
      });
    } else {
      sessionStorage.setItem('inactive_user_groups_visible', 0);
      this.inactiveUserGroupTargets.forEach((target) => {
        target.classList.add('hidden');
      });
    }
  }

  handleIncludeInPractice(e) {
    const checkbox = e.target;
    const csrfToken = document.querySelector("[name='csrf-token']")?.content;
    const headers = {
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': csrfToken,
    };

    if (checkbox.checked) {
      const requestBody = JSON.stringify({
        practice_user_group: {
          user_group_id: checkbox.dataset.userGroupId,
        },
      });
      fetch('/practice_user_groups', {
        method: 'POST',
        headers: headers,
        body: requestBody,
      })
        .then((response) => response.json())
        .then((data) => {
          checkbox.dataset.practiceUserGroupId = data.id;
        });
    } else {
      fetch(`/practice_user_groups/${checkbox.dataset.practiceUserGroupId}`, {
        method: 'DELETE',
        headers: headers,
      });
      delete checkbox.dataset.practiceUserGroupId;
    }
  }
}
export default UserGroupsController;
