import { hideSpinner, showSpinner } from "~/javascript/utilities/spinner";

export default class AssignmentList {
  constructor({ inEditMode }) {
    this.inEditMode = inEditMode;
  }

  bindList() {
    this.bindProxySignatureButtons();
  }

  get assignmentsContainer() {
    return $j('#assignments_area');
  }

  get tableSelector() {
    return $j('#assignments_area table.providers');
  }

  bindProxySignatureButtons() {
    $j('form.proxy-signature-form').on('submit', e => {
      e.preventDefault();

      showSpinner(this.tableSelector, { loadingMessage: 'Loading assignments' });

      const { action, method } = e.target;

      $j.ajax({
        url: action,
        method: method,
        data: $j(e.target).serialize(),
        success: (response) => {
          const json = JSON.parse(response);
          this.assignmentsContainer.html(json['assignments']);
        },
        always: () => {
          this.hideSpinner(this.tableSelector);
        }
      });
    });
  }
}
