// TODO this duplicates AuditLog
export default class CaseCharts {
  constructor({ loadChartPath }) {
    this.loadChartPath = loadChartPath;
  }

  bindForm() {
    $j("#case_charts_button").on('click', this.toggle.bind(this));
  }

  toggle(e) {
    e.preventDefault();

    var button = $j('#case_charts_button');
    var container= $j("#case_charts");

    $j('#case_charts').toggle();

    // charts have not yet been loaded into the page
    if (container.find('div.charts').length == 0) {
      $j.get(this.loadChartPath).then(response => {
        container.html(response);
        Event.addBehavior.reload();
      }).always(response => {
        // wait to update button text until request finishes
        button.html('Hide Charts &uarr;');
      }).fail(response => {
        container.html('Unable to load changes. Please contact an admin.');
      });
    } else {
      // update button text to match visibility of charts
      button.html(container.is(":visible") ? 'Hide Charts &uarr;' : 'Show Charts &darr;');
    }
  }
}
