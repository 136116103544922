// Filter OR drop-down based on selected location tag
const LocationTagSelect = {
  change: function() {
    var locationTag = $('case_location_tag_id');
    var opr = $('case_operating_room_id');
    if (locationTag && opr) {
      var tagId = locationTag.getValue();
      opr.options.length = 0;
      opr.options.add(new Option('', ''));
      if (operatingRooms[tagId].length > 0)
        operatingRooms[tagId].each(function(e) { opr.options.add(new Option(e[0], e[1])); });
    }
  }
}

export default LocationTagSelect;