import { initializeRxSelect } from '~/javascript/rx_select';
import _ from 'underscore';

export default class AddEditAllergyForm {
  constructor(options) {
    this.mode = options.mode;
    this.drugAutoCompleteUrl = options.drugAutoCompleteUrl;
    this.currentAllergyId = options.currentAllergyId;
  }

  bindForm() {
    if(this.mode === 'add') {
      this.bindDrugSelect();
    }
  }


  bindDrugSelect() {
    const resultsFormatter = (results) => {
      return _.map(results, (provider) => {
        const optionText = `${provider.value} - ${provider.rxcui}`;
        return { id: provider.id, text: optionText, drugName: provider.value };
      })
    };

    const drugNameField = $j(this.formId).find("select.drug-name");

    const drugSelect = initializeRxSelect({
      field: drugNameField,
      autocompleteUrl: this.drugAutoCompleteUrl,
      includeClasses: true,
      resultsFormatter
    });
  }

  get formId() {
    if(this.mode === 'add') {
      return '#add_current_allergy_form';
    } else {
      return  `#edit_current_allergy_${this.currentAllergyId}_form`;
    }
  }
}
