class PrintQueue {
  open() {
    const winHeight = document.viewport.getDimensions().height;
    window.open(
      this.href,
      'print_queue',
      'status=0,toolbar=0,location=0,menubar=0,directories=0,resizable=1,scrollbars=1,height=' +
        winHeight +
        ',width=200'
    );
    return false;
  }

  markPrinted(job) {
    $(job).addClassName('printed').removeClassName('unprinted');
    const now = new Date();
    let hour = now.getHours();
    let min = now.getMinutes();
    if (hour < 10) {
      hour = '0' + hour;
    }
    if (min < 10) {
      min = '0' + min;
    }
    return ($(job).down('.time').innerHTML = 'p' + hour + ':' + min);
  }

  printJob(job) {
    const url = $(job).readAttribute('data-print-url');
    window.open(
      url,
      'pdf',
      'status=0,toolbar=0,location=0,menubar=0,directories=0,resizable=1,scrollbars=1'
    );
    this.markPrinted(job);
    return false;
  }
}

$j(function() {
  const printQueue = new PrintQueue();
  $j("#open_print_queue").on('click', printQueue.open);
  $j("#printed_cases .printed_case").on('click', function(e) { return printQueue.printJob(this) });
});