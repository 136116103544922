import { DeleteButton, LinkButton } from '~/javascript/buttons';
import { setCSRFHeaders } from '~/javascript/utilities/ajax_helpers';
import CaseEditPanel from '~/javascript/cases/case_panels/case_edit_panel';

export default class EditCommentForm {
  constructor({ commentId }) {
    this.commentId = commentId;
  }

  bindForm() {
    const editPanel = new CaseEditPanel({
      areaSelector: '#comments_area',
      deleteButtonSelector: `#delete_comment_${this.commentId}_button`,
      errorSelector: `#edit_comment_${this.commentId}_errors`,
      formSelector: `#edit_comment_${this.commentId}_form`,
      objectId: this.commentId
    });

    editPanel.bindForm();
  }
}
