import { Controller } from 'stimulus';

class ScheduleCasesController extends Controller {
  openNewCapturedScheduleWindow(e) {
    e.preventDefault();
    const link = e.target;
    window.open(link.pathname, 'newwindow', 'width=900,height=1000');
  }
}
export default ScheduleCasesController;
