import { LinkButton } from '~/javascript/buttons';

export default class CaseAddPanel {
  constructor({ areaSelector, formSelector, errorSelector, afterSave }) {
    this.areaSelector = areaSelector;
    this.formSelector = formSelector;
    this.errorSelector = errorSelector;
    this.afterSave = afterSave || null;
  }

  bindForm() {
    $j(this.formSelector).on('submit', this.submitForm.bind(this));
  }

  submitForm(e) {
    e.preventDefault();

    LinkButton.disableElementsIn(this.formSelector, 'Adding...');

    const action = $j(this.formSelector).attr('action');
    const method = $j(this.formSelector).attr('method')

    $j.ajax({
      url: action,
      method: method,
      data: $j(this.formSelector).serialize(),
      success: (response) => {
        if(this.afterSave) {
          this.afterSave(response);
        } else {
          $j(this.areaSelector).html(response);
        }
        $j(this.errorSelector).hide();
      },
      error: (response) => {
        $j(this.errorSelector).html(response.responseText);
        $j(this.errorSelector).show();
      },
      complete: () => {
        LinkButton.enableElementsIn(this.formSelector, 'Add');
      }
    });
  }
}
