export default class DemographicAndInsuranceForm {
  constructor({ formId, autoCompleteUrl }) {
    this.formId = formId;
    this.autoCompleteUrl = autoCompleteUrl;
  }

  bindKeySelect() {
    $j(`#${this.formId}`).find('.demographic-and-insurance-key').select2({
      ajax: {
        delay: 250,
        dataType: 'json',
        url: this.autoCompleteUrl },
      minimumInputLength: 1,
      width: 175,
    });
  }

  bindForm() {
    $j(() => {
      this.bindKeySelect();
    });
  }
}
