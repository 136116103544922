import _ from 'underscore';
import { processResults } from '~/javascript/utilities/autocompleter';

export default class CodedAutoComplete {
  constructor(field) {
    this.field = field;
    this.bindField();
  }

  bindField() {
    const valueSetId = $j(this.field).data('value-set');

    const resultsFormatter = (results) => {
      return _.map(results, (result) => {
        const optionText = `${result.name} - ${result.code}`;
        return { id: result.id, text: optionText };
      })
    };

    $j(this.field).select2({
      width: 600,
      allowClear: true,
      minimumInputLength: 1,
      ajax: {
        url: '/concepts',
        delay: 250,
        data: (params) => {
          return { term: params.term, value_set_id: valueSetId }
        },
        dataType: 'json',
        processResults: (data, params) => {
          return processResults(data, params, resultsFormatter);
        }
      }
    });
  }
}
