import { LinkButton } from '~/javascript/buttons';
import CaseAddPanel from '~/javascript/cases/case_panels/case_add_panel';

export default class AddCommentForm {
  constructor({ formId }) {
    this.formId = formId;
  }

  bindForm() {
    const addPanel = new CaseAddPanel({
      areaSelector: '#comments_area',
      errorSelector: `#${this.formId}_errors`,
      formSelector: `#${this.formId}_form`
    });

    addPanel.bindForm();
  }
}
