import { Controller } from 'stimulus';
import date_time_picker from '~/javascript/date_time_picker';

class ClinicalDataExportController extends Controller {
  connect() {
    date_time_picker('#date_range_start_wrapper')
    date_time_picker('#date_range_end_wrapper')
  }

  checkAll(event) {
    this.setCheckState(event, true)
  }

  uncheckAll(event) {
    this.setCheckState(event, false)
  }
  
  setCheckState(event, checked) {
    event.preventDefault()
    let panel = $j(event.target).closest('.panel')
    panel.find('input[type=checkbox]').prop('checked', checked)
  }
}

export default ClinicalDataExportController;
