import { Chosen } from 'chosen-js/chosen.proto';

class AjaxChosen extends Chosen {
  constructor(select, options, callback) {
    super(select, options);
    const chosen = $(this);
    select.next('.chosen-container').down('.search-field > input').observe('keyup', function() {
      var search_field, val;
      val = $(this).value.strip();
      if (window.ajaxChosenDelayTimer) {
        clearTimeout(window.ajaxChosenDelayTimer);
        window.ajaxChosenDelayTimer = null;
      }
      search_field = $(this);
      return window.ajaxChosenDelayTimer = setTimeout(function() {
        var query_key, success;
        if (val === search_field.readAttribute('data-prevVal')) {
          return false;
        }
        search_field.writeAttribute('data-prevVal', val);
        query_key = options.query_key || "term";
        (options.parameters || (options.parameters = {}))[query_key] = val;
        success = options.success;
        options.onSuccess = function(data) {
          var items;
          if (data == null) {
            return;
          }
          select.childElements().each(function(el) {
            if (!el.selected) {
              return el.remove();
            }
          });
          items = callback ? callback(data.responseJSON) : data.responseJSON;
          $H(items).each(function(pair) {
            if (select.value !== pair.key) {
              return select.insert({
                bottom: new Element("option", {
                  value: pair.key
                }).update(pair.value)
              });
            }
          });
          val = search_field.value;
          select.fire("chosen:updated");
          search_field.value = val;
          chosen.winnow_results();
          if (success != null) {
            return success();
          }
        };
        return new Ajax.Request(options.url, options);
      }, 300);
    });
  }

  activateField() {
    if (this.options.show_on_activate && !this.active_field) {
      this.results_show();
    }

    return super.activateField();
  }
}

export default AjaxChosen;

