import ModalController from './modal_controller';

var idleTime = 0;

class PinPadModalController extends ModalController {
  connect() {
    this.checkForTimeout();
    this.startMonitoring();
  }

  checkForTimeout() {
    var data = this.element.dataset;
    var check_url = data.checkUrl;
    var modalShowing = data.showing;
    if (modalShowing == 'false') {
      fetch(check_url, { method: 'GET' })
        .then((response) => response.json())
        .then((data) => {
          if (data != '0') {
            $j('.calendar_date_select').hide();
            $j('.calendar_popup').hide();
            this.showModal();
          }
        });
    }
  }

  monitorTimer(klass, location_timeout) {
    idleTime = parseInt(idleTime) + 1000;
    if (idleTime >= location_timeout) {
      klass.checkForTimeout();
    }
  }

  startMonitoring() {
    var data = this.element.dataset;
    var location_timeout = data.locationTimeout;

    setInterval(this.monitorTimer, 1000, this, location_timeout);

    var keyPressListener = function () {
      idleTime = 0;
    };
    document.addEventListener('keypress', keyPressListener, false);

    var mouseMoveListener = function () {
      idleTime = 0;
    };
    document.addEventListener('mousemove', mouseMoveListener, false);
  }
}

export default PinPadModalController;
