import date_time_picker from '~/javascript/date_time_picker';

export default class LabTestForm {
  constructor({ mode, labTestId }) {
    this.mode = mode;
    this.labTestId = labTestId;
  }

  bindForm() {
    const testDateInput = $j(this.formId).find('.test_date_wrapper');
    date_time_picker(testDateInput);

    const orderDateInput = $j(this.formId).find('.order_date_wrapper');
    date_time_picker(orderDateInput, {
      defaultDate: new Date()
    });
  }

  get formId() {
    if(this.mode === 'add') {
      return "#add_lab_test_form";
    }

    return `#edit_lab_test_${this.labTestId}_form`;
  }
}
