import { LinkButton } from '~/javascript/buttons';
import { render as renderClinicalCompletionInfo } from '~/javascript/cases/clinical_case_completion_info';

class CasePanel {
  constructor(section_container, base_id, options) {
    this.section_container = section_container;
    this.base_id = base_id;
    this.options = options;

    $j(() => {
      this.bind_form_callbacks();
      this.bind_delete_callbacks();
    });
  }

  get formSelector() {
    return `#${this.base_id}_form`;
  }

  bind_form_callbacks() {
    if(this.options && this.options.fullSubmit) {
      return;
    }

    const form = $j(this.formSelector);
    $j(form).on('submit', this.submitForm.bind(this));
  }

  bind_delete_callbacks() {
    const delete_button = $j(`#delete_${this.base_id}_button`);

    delete_button.removeData('remote');
    delete_button.removeData('method');

    $j(delete_button).on('click', e => {
      e.preventDefault();

      const confirmText = delete_button.data('confirm');
      const confirmResult = confirm(confirmText);

      if(!confirmResult) {
        return false;
      }

      $j(e.target).attr('disabled', true);

      const csrf = $j('meta[name="csrf-token"]').attr('content');

      $j.ajax({
        url: $j(e.target).attr('href'),
        method: 'delete',
        beforeSend: function(xhr) { xhr.setRequestHeader('X-CSRF-Token', csrf ) },
        success: (response) => {
          this.replaceSectionContent(response);
          renderClinicalCompletionInfo();
        },
        error: (e) => {
          this.replaceErrorsContent(e.responseText);
        },
        complete: (response) => {
          $j(e.target).attr('disabled', false);
        }
      });

      return false;
    });
  }

  submitForm(e) {
    e.preventDefault();

    var csrf = $j('meta[name="csrf-token"]').attr('content');

    this.disableSaveButton(e);

    const action = $j(this.formSelector).attr('action');
    const method = $j(this.formSelector).attr('method');

    $j.ajax({
      beforeSend: function(xhr) { xhr.setRequestHeader('X-CSRF-Token', csrf ) },
      url: action,
      method: method,
      data: $j(this.formSelector).serialize(),
      success: (response) => {
        this.replaceSectionContent(response);
      },
      error: (e) => {
        this.replaceErrorsContent(e.responseText);
      },
      complete: () => {
        this.enableSaveButton();
      }
    });
  }

  disableSaveButton(e) {
    return LinkButton.disableElementsIn("#" + this.base_id + "_form", 'Saving...');
  }

  enableSaveButton = function() {
    return LinkButton.enableElementsIn("#" + this.base_id + "_form", 'Save');
  }

  replaceSectionContent(new_content) {
    return $j(`#${this.section_container}`).html(new_content);
  }

  replaceErrorsContent(new_content) {
    return $j(`#${this.base_id}_errors`).html(new_content);
  };
}

export default CasePanel;
