// aliases for jquery, we need to remove this when
// prototype is gone.
import './vite-application.scss'
window.$j = jQuery

// Hotwire/Stimulus
import '~/controllers/index'
import * as Turbo from '@hotwired/turbo'
import '@hotwired/turbo-rails'
Turbo.start()
Turbo.session.drive = false

import select2 from 'select2'
import 'select2/dist/css/select2.css'
select2()

import '~/javascript/header'
import 'jquery-mask-plugin'

import {
  addHours,
  addYears,
  differenceInDays,
  endOfDay,
  endOfToday,
  endOfYear,
  format,
  isValid,
  parse,
  parseISO,
  startOfDay,
  startOfToday,
  startOfYear,
  subHours,
  subYears,
} from 'date-fns';

window.dateFns = {
  addHours: addHours,
  addYears: addYears,
  differenceInDays: differenceInDays,
  endOfDay: endOfDay,
  endOfToday: endOfToday,
  endOfYear: endOfYear,
  format: format,
  isValid: isValid,
  parse: parse,
  parseISO: parseISO,
  startOfDay: startOfDay,
  startOfToday: startOfToday,
  startOfYear: startOfYear,
  subHours: subHours,
  subYears: subYears,
}

import dragula from 'dragula'
import 'dragula/dist/dragula.css'
window.dragula = dragula

import 'flatpickr'
import 'flatpickr/dist/flatpickr.css'
import ('flatpickr/dist/plugins/monthSelect/style.css')

import 'jquery-modal/jquery.modal.min.css';
import 'jquery-modal/jquery.modal.min.js';

import { Chosen } from 'chosen-js/chosen.proto';
import 'chosen-js/chosen.css';
import AjaxChosen from '~/javascript/extensions/ajax.chosen';
window.ajaxChosen = AjaxChosen;
window.Chosen = Chosen;

import DelayRunner from '~/javascript/utilities/delay_runner';
import AjaxPeriodicalUpdater from '~/javascript/utilities/ajax_periodical_updater';
import { AddPanel, EditPanel } from '~/javascript/panels';
import SlidingView from '~/javascript/sliding_view';
import { DeleteButton, LinkButton, SubmitButton } from '~/javascript/buttons';
import { Selectable, SelectableOption } from '~/javascript/selectable';
import SelectableRow from '~/javascript/selectable_row';
import AuditLog from '~/javascript/audit_log';
import CasePanel from '~/javascript/case_panel';
import '~/javascript/print_queue';
import LinkToggle from '~/javascript/link_toggle';
import DatetimeSelectWithDaylightSavingDisambiguation from '~/entrypoints/datetime_select_with_daylight_saving_disambiguation';
import RaceForm from '~/javascript/race_form';
import AssistorFields from '~/javascript/cases/assignments/assistor_fields';
import { bindApplicationEvents } from '~/javascript/application_events';
import CodedAutoComplete from '~/javascript/coded_autocomplete';
import date_time_picker from '~/javascript/date_time_picker';
import ViewStatus from '~/javascript/view_status';
import ArchiveSearch from '~/javascript/archive_search';
import TodayAddCaseForm from '~/javascript/today_add_case_form'
import EditPractice from "~/javascript/admin/edit_practice";
import '~/javascript/cases/index';
import ClinicalInformationReconciliation from '~/javascript/cases/clinical_information_reconciliation';
import '~/javascript/autocomplete_off';

window.delayRunner = new DelayRunner();
window.AjaxPeriodicalUpdater = AjaxPeriodicalUpdater;
window.AddPanel = AddPanel;
window.EditPanel = EditPanel;
window.SlidingView = SlidingView;
window.DeleteButton = DeleteButton;
window.LinkButton = LinkButton;
window.SubmitButton = SubmitButton;
window.Selectable = Selectable;
window.SelectableRow = SelectableRow;
window.SelectableOption = SelectableOption;
window.AuditLog = new AuditLog();
window.CasePanel = CasePanel;

const linkToggle = new LinkToggle();
linkToggle.bindLinks();

window.DatetimeSelectWithDaylightSavingDisambiguation = DatetimeSelectWithDaylightSavingDisambiguation;
window.RaceForm = RaceForm;
window.AssistorFields = AssistorFields;
bindApplicationEvents();
window.CodedAutoComplete = CodedAutoComplete;
window.date_time_picker = date_time_picker;
window.ViewStatus = ViewStatus;
window.ArchiveSearch = ArchiveSearch;
window.TodayAddCaseForm = TodayAddCaseForm;
window.EditPractice = EditPractice
window.ClinicalInformationReconciliation = ClinicalInformationReconciliation;

import '~/javascript/contract_utilization'

import Select2Init from '~/javascript/select2_init'
new Select2Init()
