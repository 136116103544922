const ClinicalInformationReconciliation = {
  showOverlay: function(e) {
    e.preventDefault();

    $j('#page').prepend('<div id="clinical_information_reconciliation_busy_overlay" class="page-busy-overlay"></div>');
    $j('#clinical_information_reconciliation_overlay').show();

    $j.get(e.target.href, {}, response => {
      $j('#clinical_information_reconciliation_overlay').hide();
      $j('#page').prepend(response);
      if ($j('#scrollable_pane_contents').height() > 375) {
        $j("#scroll_affordance").addClass("show");
      }
    });
  },
  cancelDetails: function(event) {
    $j('#clinical_information_reconciliation_details').remove();
    $j('#clinical_information_reconciliation_busy_overlay').remove();
  },
  checkboxClicked: function(event) {
    const tr = $j(event.target).closest('tr');
    const type = $j(event.target).closest('td').hasClass('case') ? 'toc' : 'case';
    const uncheckbox = tr.find("td." + type + " input[type=checkbox]");
    if (uncheckbox.length > 0) {
      uncheckbox.attr('checked', false);
    }
    const merged = event.target.checked ? $j(event.target).nextAll('.content').html() : '';
    tr.find('td.merged .content').html(merged);
    tr.find('td.merged .content .last-modified').html(dateFns.format(new Date(), "MMM d',' yyyy"));
  }
};

export default ClinicalInformationReconciliation;
