import _ from 'underscore';
import { processResults } from '~/javascript/utilities/autocompleter';

export default class CptSelect {
  constructor(selector) {
    const resultsFormatter = (results) => {
      return _.map(results, result => {
        const { value, info } = result;
        const id = `[${value}] ${info}`;
        const text = `${value} - ${info}`;

        return { id, text };
      });
    };

    $j(selector).select2({
      width: 300,
      minimumInputLength: 1,
      ajax: {
        url: '/cpts/auto_complete',
        delay: 250,
        data: (params) => {
          return {
            code: params.term
          }
        },
        dataType: 'json',
        processResults: (data, params) => {
          return processResults(data, params, resultsFormatter)
        }
      }
    });
  }
}
