const toggle = (e, state) => {
  e.preventDefault();

  const link = $j(e.target);
  const table = link.parent().siblings('table');

  table.toggleClass("show-active", state === 'active');
  link.siblings(".selected").removeClass("selected");
  link.addClass("selected");
};

const ViewStatus = {
  active: function(e) {
    toggle(e, 'active');
  },
  all: function(e) {
    toggle(e, 'all');
  }
};

export default ViewStatus;