import date_time_picker from "~/javascript/date_time_picker";

class CaseExecutableForm {
  constructor(containerId) {
    this.containerId = containerId;
  }

  isEditMode() {
    return this.containerId.indexOf('edit') > -1;
  }

  bindForm() {
    const wrapper = $j(this.containerId).find('.executed_at_wrapper');

    date_time_picker(wrapper, {
      allowInput: false,
      defaultDate: new Date(),
      enableTime: true
    });

    $j(this.containerId).on('submit', (e) => {
      (this.isEditMode() ? this.checkEditCaseExecutableForm(e) : this.checkNewCaseExecutableForm(e));
      LinkButton.disableElementsIn(this.containerId, 'Saving...');
    });
  }

  fileIsSelected() {
    return $j(this.containerId).find('.case_executable_form_upload_file').val() != '';
  }

  fileIsPdf() {
    if (this.fileIsSelected()) {
      var fName = $j(this.containerId).find('.case_executable_form_upload_file').val();
      return !!(/\.pdf$/i).exec(fName);
    } else {
      return true
    }
  }

  executedAtIsSet() {
    return $j(this.containerId).find('.executed_at_cal').val() != '';
  }

  checkNewCaseExecutableForm(e) {
    var errors = [];

    if (!this.fileIsSelected()) {
      errors.push('PDF file required');
    }

    if (!this.fileIsPdf()) {
      errors.push('File must be PDF');
    }

    if (!this.executedAtIsSet()) {
      errors.push('Executed at must be set');
    }

    if (errors.length) {
      e.preventDefault();
      alert(errors.join("\r\n"));
    }
  }

  checkEditCaseExecutableForm(e) {
    var errors = [];

    if (!this.fileIsPdf()) {
      errors.push('File must be PDF');
    }

    if (!this.executedAtIsSet()) {
      errors.push('Executed at must be set');
    }

    if (errors.length) {
      e.preventDefault();
      alert(errors.join("\r\n"));
    }
  }
}

export default CaseExecutableForm;
