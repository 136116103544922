import { Controller } from 'stimulus';
import date_time_picker from '~/javascript/date_time_picker';

const MAX_NUM_OF_LOCATIONS = 25;

class OrUtilizationController extends Controller {
  static targets = ['inactiveToggle', 'operatingRoom', 'inactiveOperatingRoom'];

  connect() {
    date_time_picker('#date_range_start_wrapper');
    date_time_picker('#date_range_end_wrapper');
  }

  toggleInactiveOrs() {
    this.inactiveOperatingRoomTargets.forEach(inactiveContainer => {
      let checkbox = inactiveContainer.querySelector('input[type=checkbox]');
      checkbox.checked = false;
      if (this.inactiveToggleTarget.checked) {
        inactiveContainer.classList.remove('hidden');
        checkbox.disabled = false;
      } else {
        inactiveContainer.classList.add('hidden');
        checkbox.disabled = true;
      }
    })
  }

  selectAll(event) {
    this.setAllChecked(event, true);
  }

  selectNone(event) {
    this.setAllChecked(event, false);
  }

  setAllChecked(event, checked) {
    let panel = event.target.closest('.panel');
    panel.querySelectorAll('input[type=checkbox]').forEach(checkbox => {
      checkbox.checked = checked;
    })
  }

  toggleOperatingRoom(event) {
    let panel = event.target.closest('.panel');
    let checkedOrs = panel.querySelectorAll('input[type=checkbox]:checked');
    let uncheckedOrs = panel.querySelectorAll('input[type=checkbox]:not(:checked)');
    if (checkedOrs.length >= MAX_NUM_OF_LOCATIONS) {
      uncheckedOrs.forEach(uncheckedBox => {
        uncheckedBox.disabled = true;
      })
    } else {
      uncheckedOrs.forEach(uncheckedBox => {
        uncheckedBox.disabled = false;
      })
    }
  }
}

export default OrUtilizationController;