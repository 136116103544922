const isPdfLink = (e) => {
  return ($j(e.target).hasClass('pdflink'));
};

const isLink = (e) => {
  return (e.target.nodeName == 'A' &&
    ($j(e.target.parentNode).hasClass('action') || $j(e.target.parentNode).hasClass('link-container')));
};

const isSubmitButton = (e) => {
  return (e.target.type == 'submit' &&
    $j(e.target).hasClass('submit') && $j(e.target).hasClass('button'));
};

const SelectableRow = {
  click: function(e) {

    if(isPdfLink(e)) {
      window.open(e.target.parentElement.href,'_blank');
      return false;
    }

    if(isLink(e) || isSubmitButton(e)) {
      return;
    }

    var node = (this.parentNode.id) ? this.parentNode : this;

    if ($j(node).hasClass('not_selectable')) return; // don't handle clicks for non-selectable rows

    if (node.id || this.id) {
      if ($j("#" + node.id + '_url').length > 0) {
        window.location.href = $j("#" + node.id + '_url').attr('href')
      } else if ($j('#' + 'edit_' + node.id).length > 0) {
        var editNode = $j('#' + 'edit_' + node.id);
        EditPanel.toggle(editNode.attr('id'));
        (editNode.is(':visible')) ? $j(node).addClass('editing') : $j(node).removeClass('editing');
      }
    }
    return false;
  }
};

export default SelectableRow;