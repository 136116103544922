import CaseAddPanel from '~/javascript/cases/case_panels/case_add_panel';
import { render as renderClinicalCompletionInfo } from '~/javascript/cases/clinical_case_completion_info';

export default class AddAssignmentForm {
  bindForm() {
    AssistorFields.toggle();
    $j('#add_assignment_form #assignment_role').on('change', AssistorFields.toggle);

    const addPanel = new CaseAddPanel({
      areaSelector: '#assignments_area',
      errorSelector: '#add_provider_errors',
      formSelector: '#add_assignment_form',
      afterSave: (response) => {
        $j('#assignments_area').html(response);
        renderClinicalCompletionInfo();
      }
    });

    addPanel.bindForm();
  }
}
