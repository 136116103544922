import { Controller } from 'stimulus'

class LocationsVitalsController extends Controller {
  static targets = [ 
    'enableMonitorFeed', 
    'enableVitalsFeed', 
    'enableAutoConfirm'
  ]

  connect() {
    this.autoConfirm()
  }

  autoConfirm() {
    var monitorChecked = this.enableMonitorFeedTarget.checked
    var vitalsChecked = this.enableVitalsFeedTarget.checked
    if(monitorChecked || vitalsChecked) {
      this.enableAutoConfirmTarget.disabled = false 
    } else {
      this.enableAutoConfirmTarget.disabled = true 
      this.enableAutoConfirmTarget.checked = false 
    }
  }
}
export default LocationsVitalsController
