import CptSelect from '~/javascript/cases/procedures/cpt_select';

export default class RadiologyOrdersForm {
  constructor({ radiologyOrderId, mode }) {
    this.radiologyOrderId = radiologyOrderId;
    this.mode = mode;
  }

  bindForm() {
    const cptInput = $j(this.formId).find('select.cpt');
    new CptSelect(cptInput);

    const orderDateField = $j(this.formId).find('.order_date_wrapper');
    date_time_picker(orderDateField, {
      defaultDate: new Date()
    });
  }

  get formId() {
    if(this.mode === 'add') {
      return "#add_radiology_order_form";
    } else {
      return `#edit_radiology_order_${this.radiologyOrderId}_form`;
    }
  }
}
