import { Controller } from 'stimulus';

let pin = [];

class PinPadKeysController extends Controller {
  static targets = ['circle', 'key', 'form'];
  static outlets = ['pin-pad-modal'];

  chooseNumber(e) {
    var num = e.target.innerText.toString();
    if (pin.length <= 3) {
      pin.push(num);
      this.checkPinStatus();
    }
  }

  checkPinStatus() {
    this.fillCircles();
    if (pin.length == 4) {
      this.checkPinWithServer();
    }
  }

  checkPinWithServer() {
    var url = this.formTarget.action;
    var form = this.formTarget;
    form['pin'].value = pin.join('');
    var formData = new FormData(this.formTarget);
    fetch(url, { method: 'POST', body: formData })
      .then((response) => {
        if (response.ok) {
          this.resetPin();
          this.resetCircles();
          this.pinPadModalOutlet.hideModal();
          if (form['current_pin_user_id'].value != form['pin_user_id'].value) {
            location.reload();
          }
          return;
        }
        return Promise.reject(response);
      })
      .catch(() => {
        this.errorCircles();
      });
  }

  errorCircles() {
    this.circleTargets.forEach((elem) => {
      elem.classList.remove('circle-filled', 'circle-success');
      elem.classList.add('circle-fail');
    });
    setTimeout(() => {
      this.resetPin();
      this.resetCircles();
    }, 1600);
  }

  resetCircles() {
    this.circleTargets.forEach((elem) => {
      elem.classList.remove('circle-filled', 'circle-fail');
    });
  }

  resetPin() {
    pin = [];
    this.formTarget['pin'].value = '';
  }

  fillCircles() {
    pin.forEach((_, i) => {
      this.circleTargets[i].classList.add('circle-filled');
    });
  }

  deleteLast() {
    if (pin.length > 0) {
      pin.pop();
      this.resetCircles();
      this.checkPinStatus();
    }
  }
}
export default PinPadKeysController;
