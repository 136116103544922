import date_time_picker from '~/javascript/date_time_picker';

export default class ImmunizationForm {
  constructor({ immunizationId }) {
    this.immunizationId = immunizationId;
  }

  statusAllowsAdditionalNotes() {
    const additionalNoteStatuses = [
      "aborted",
      "cancelled"
    ];

    const selectedStatus = $j("option:selected", this.dropdown).text().toLowerCase();
    return additionalNoteStatuses.indexOf(selectedStatus) > -1;
  }

  hideAdditionalNotes() {
    this.notesField.val('');
    this.notesContainer.hide();
  }

  showAdditionalNotes() {
    this.notesContainer.show();
  }

  handleStatusChange() {
    this.statusAllowsAdditionalNotes() ? this.showAdditionalNotes() : this.hideAdditionalNotes();
  }

  bindForm() {
    this.dropdown = $j("[name='immunization[status_id]'][data-id='" + this.immunizationId + "']");
    const form = this.dropdown.closest('form');

    const dateInput = form.find('.date_wrapper');
    date_time_picker(dateInput, {
      defaultDate: new Date()
    });

    this.notesField = form.find("[name='immunization[additional_notes]']");
    this.notesContainer = form.find(".additional-notes-container");

    this.dropdown.change(() => { this.handleStatusChange() });
    this.handleStatusChange();

    $j("#add_immunization_button").on('click', () => {
      this.dropdown.selectedIndex = 0;
      this.handleStatusChange();
    });
  }
};
