import { LinkButton, DeleteButton } from '~/javascript/buttons';
import { setCSRFHeaders } from '~/javascript/utilities/ajax_helpers';
import CaseEditPanel from '../case_panels/case_edit_panel';

export default class EditEventForm {
  constructor({ eventId }) {
    this.eventId = eventId;
  }

  bindForm() {
    const editPanel = new CaseEditPanel({
      afterUpdate: (response) => {
        var json = JSON.parse(response);
        $j('#events_area').html(json['events']);
        $j('#assignments_area').html(json['providers']);
      },
      areaSelector: '#events_area',
      deleteButtonSelector: `#delete_event_${this.eventId}_button`,
      errorSelector: `#edit_event_${this.eventId}_errors`,
      formSelector: `#edit_event_${this.eventId}_form`,
      objectId: this.eventId
    });

    editPanel.bindForm();
  }
}
