import _ from 'underscore';

class DelayRunner {
  constructor() {
    this.items = [];
  }

  add(item) {
    this.items = this.items.concat(item);
    return this.run();
  }

  run() {
    if (this.items.length === 0 || this.readyStateCheckInterval) {
      return;
    }

    const intervalFunction = function () {
      var i, item, len, ref;
      if (document.readyState === 'complete') {
        ref = [...this.items];
        for (i = 0, len = ref.length; i < len; i++) {
          item = ref[i];
          this.items = _.filter(this.items, (x) => x !== item);
          item();
        }
        if (this.items.length === 0) {
          clearInterval(this.readyStateCheckInterval);
          return (this.readyStateCheckInterval = false);
        }
      }
    };
    return (this.readyStateCheckInterval = setInterval(
      intervalFunction.bind(this),
      10
    ));
  }
}

export default DelayRunner;
