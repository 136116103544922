import { Controller } from "stimulus"

// This controller is just here as the very start of
// adding StimulusJS.  It is here to show that you can
// load up multiple controllers in a div.
class CancellationReasonController extends Controller {

  initialize() { }
  connect() { }
  disconnect() { }

}
export default CancellationReasonController
