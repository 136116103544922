import AddEditAllergyForm from '~/javascript/cases/allergy_forms/add_edit_allergy_form';
import AddAssignmentForm from '~/javascript/cases/assignments/add_assignment_form';
import AssignmentList from '~/javascript/cases/assignments/assignment_list';
import EditAssignmentForm from '~/javascript/cases/assignments/edit_assignment_form';
import BatchReview from '~/javascript/cases/batch_review';
import AddBillingCodeForm from '~/javascript/cases/billing_codes/add_billing_code_form';
import EditBillingCodeForm from '~/javascript/cases/billing_codes/edit_billing_code_form';
import CaseCharts from '~/javascript/cases/case_charts';
import EditCaseInfo from '~/javascript/cases/edit_case_info';
import CognitiveStatusForm from '~/javascript/cases/cognitive_statuses/form';
import AddCommentForm from '~/javascript/cases/comments/add_comment_form';
import EditCommentForm from '~/javascript/cases/comments/edit_comment_form';
import AddDiagnosisForm from '~/javascript/cases/diagnoses/add_diagnosis_form';
import EditDiagnosisForm from '~/javascript/cases/diagnoses/edit_diagnosis_form';
import EditEventForm from '~/javascript/cases/events/edit_event_form';
import AddEventForm from '~/javascript/cases/events/add_event_form';
import FunctionalStatusForm from '~/javascript/cases/functional_statuses/form';
import ImmunizationForm from '~/javascript/cases/immunizations/form';
import AddEditMedForm from '~/javascript/cases/med_forms/add_edit_med_form';
import ProcedureForm from '~/javascript/cases/procedures/form';
import RadiologyOrdersForm from '~/javascript/cases/radiology_orders/form';
import CaseExecutableForm from '~/javascript/cases/case_executable_form';
import LabTestForm from '~/javascript/cases/lab_tests/form';
import MedicationOrderForm from '~/javascript/cases/medication_orders/form';
import CorrectCaseForm from '~/javascript/cases/correct_case_form';
import DemographicAndInsuranceForm from '~/javascript/cases/demographic_and_insurance_data/form';
import ClinicalCaseCompletionInfo from '~/javascript/cases/clinical_case_completion_info';

window.bindEditCaseInfo = () => {
  const editCaseInfo = new EditCaseInfo();
  editCaseInfo.bindForm();
}

window.bindAddEditAllergyForm = (options) => {
  const addEditAllergyForm = new AddEditAllergyForm(options);
  addEditAllergyForm.bindForm();
};

window.bindEditAssignmentForm = (options) => {
  const editAssignmentForm = new EditAssignmentForm(options);
  editAssignmentForm.bindForm();
};

window.bindAddAssignmentForm = (options) => {
  const addAssignmentForm = new AddAssignmentForm(options);
  addAssignmentForm.bindForm();
};

window.bindAssignmentList = (options) => {
  const assignmentList = new AssignmentList(options);
  assignmentList.bindList();
}

window.bindBatchReview = () => {
  const batchReview = new BatchReview();
  batchReview.bindForm();
};

window.bindAddBillingCodeForm = (options) => {
  const addBillingCodeForm = new AddBillingCodeForm(options);
  addBillingCodeForm.bindForm();
};

window.bindEditBillingCodeForm = (options) => {
  const editBillingCodeForm = new EditBillingCodeForm(options);
  editBillingCodeForm.bindForm();
};

window.bindCaseCharts = (options) => {
  const caseCharts = new CaseCharts(options);
  caseCharts.bindForm();
};

window.bindCognitiveStatusForm = (options) => {
  const form = new CognitiveStatusForm(options);
  form.bindForm();
};

window.bindCognitiveStatusForm = (options) => {
  const form = new CognitiveStatusForm(options);
  form.bindForm();
};

window.bindAddCommentForm = (options) => {
  const addCommentForm = new AddCommentForm(options);
  addCommentForm.bindForm();
};

window.bindEditCommentForm = (options) => {
  const editCommentForm = new EditCommentForm(options);
  editCommentForm.bindForm();
};

window.bindAddDiagnosisForm = (options) => {
  const form = new AddDiagnosisForm(options);
  form.bindForm();
};

window.bindEditDiagnosisForm = (options) => {
  const form = new EditDiagnosisForm(options);
  form.bindForm();
};

window.bindAddEventForm = () => {
  const addEventForm = new AddEventForm();
  addEventForm.bindForm();
};

window.bindEditEventForm = (options) => {
  const editEventForm = new EditEventForm(options);
  editEventForm.bindForm();
};

window.bindFunctionalStatusForm = (options) => {
  const form = new FunctionalStatusForm(options);
  form.bindForm();
};

window.bindImmunizationForm = (options) => {
  var immunizationForm = new ImmunizationForm(options);
  immunizationForm.bindForm();
};

window.bindAddEditMedForm = (options) => {
  const medForm = new AddEditMedForm(options);
  medForm.bindForm();
};

window.bindProcedureForm = (options) => {
  const procedureForm = new ProcedureForm(options);
  procedureForm.bindForm();
};

window.bindRadiologyOrdersForm = (options) => {
  const form = new RadiologyOrdersForm(options);
  form.bindForm();
};

window.maskSmokingDates = () => {
  $j('.smoking-date').mask("0000-00-00", { placeholder: "yyyy-mm-dd" });
};

window.bindCaseExecutableForm = function(containerId) {
  const form = new CaseExecutableForm(containerId);
  form.bindForm();
};

window.bindLabTestForm = (options) => {
  const form = new LabTestForm(options);
  form.bindForm();
};

window.bindMedicationOrderForm = (options) => {
  const form = new MedicationOrderForm(options);
  form.bindForm();
};

window.bindCorrectCaseForm = (options) => {
  const form = new CorrectCaseForm(options);
  form.bindForm();
};

window.bindDemographicAndInsuranceForm = (options) => {
  const form = new DemographicAndInsuranceForm(options);
  form.bindForm();
};
