class SlidingView {
  constructor(prefix) {
    this.prefix = prefix;
  }

  open() {
    this.toggle();
    return false;
  }

  toggle() {
    $j(`#${this.prefix}_slider`).slideToggle();

    const sliderStatus = $j('div#slider-status');
    sliderStatus.attr('class', sliderStatus.attr('class') == 'open' ? 'closed' : 'open');
  }

  error(e) {
    const flashSelector = $j(`#${this.prefix}_flash`);
    flashSelector.html(e.responseText);
    flashSelector.show();
  }

  disable_save_button_with(text) {
    const saveButton = $j(`#${this.prefix}_button`);

    this.previous_save_text = saveButton.val();
    saveButton.val(text);
    saveButton.attr('disabled', 'disabled');
  }

  enable_save_button() {
    const saveButton = $j(`#${this.prefix}_button`);

    saveButton.val(this.previous_save_text);
    saveButton.removeAttr('disabled');
  }
}

export default SlidingView;
