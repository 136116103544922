// Enable/disable ASA reason drop-down based on ASA number
const AsaField = {
  change: function() {
    var asa = $j('#case_asa');
    var asaReason = $j('#case_asa_reason_id');
    if (asa && asaReason) {
      const enableReason = (asa.val() >=3 && asa.val() <= 5);
      asaReason.attr('disabled', !enableReason);
    }
  }
};

export default AsaField;