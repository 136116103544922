import { Controller } from 'stimulus';

class ImageLabelsEditController extends Controller {
  static targets = [
    'billingSheet',
    'defaultImageFormat',
    'defaultImageFormatContainer',
    'imageAssociation',
    'imageAttributes',
    'imageType',
    'printedRecord'
  ];

  connect() {
    this.handleAssociationChange();
    this.handleImageTypeChange();
  }

  handleAssociationChange() {
    if (this.imageAssociationTarget.selectedOptions[0].text == 'Location') {
      this.printedRecordTarget.checked = false;
      this.printedRecordTarget.disabled = true;
      this.billingSheetTarget.checked = false;
      this.billingSheetTarget.disabled = true;
      this.imageAttributesTarget.value = '';
      this.imageAttributesTarget.disabled = true;
    } else {
      this.printedRecordTarget.disabled = false;
      this.billingSheetTarget.disabled = false;
      this.imageAttributesTarget.disabled = false;
    }
  }

  handleImageTypeChange() {
    if (this.imageTypeTarget.selectedOptions[0].text == 'document') {
      this.defaultImageFormatContainerTarget.classList.add('required');
      this.defaultImageFormatTarget.disabled = false;
    } else {
      this.defaultImageFormatContainerTarget.classList.remove('required');
      this.defaultImageFormatTarget.value = '';
      this.defaultImageFormatTarget.disabled = true;
    }
  }
}

export default ImageLabelsEditController;
