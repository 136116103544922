import date_time_picker from "~/javascript/date_time_picker";

export default class MedicationOrderForm {
  constructor({ mode, medicationOrderId }) {
    this.mode = mode;
    this.medicationOrderId = medicationOrderId;
  }

  bindForm() {
    const orderDateInput = $j(this.formId).find('.order_date_wrapper');
    date_time_picker(orderDateInput, {
      defaultDate: new Date()
    });
  }

  get formId() {
    if(this.mode === 'add') {
      return "#add_medication_order_form";
    }

    return `#edit_medication_order_${this.medicationOrderId}_form`;
  }
}
