import _ from 'underscore';
import { processResults } from '~/javascript/utilities/autocompleter';
import { LinkButton } from '~/javascript/buttons';
import CaseAddPanel from '~/javascript/cases/case_panels/case_add_panel';
import { refreshProblemsArea } from '~/javascript/cases/problems/problem_panel';
import { addSaveShortcut } from '~/javascript/application_shortcuts';
export default class AddDiagnosisForm {
  constructor({ formId, autocompleteUrl, selectId, icdType }) {
    this.formId = formId;
    this.autocompleteUrl = autocompleteUrl;
    this.selectId = selectId;
    this.icdType = icdType;
  }

  bindForm() {
    this.bindIcdSelect();
    this.bindAddForm();
  }

  bindIcdSelect() {
    $j(this.selectId).select2({
      width: 400,
      minimumInputLength: 1,
      ajax: {
        url: this.autocompleteUrl,
        delay: 250,
        data: (params) => {
          return {
            code: params.term
          };
        },
        dataType: 'json',
        processResults: (data, params) => {
          return processResults(data, params, this.formatIcdResults);
        }
      }
    });
  }

  formatIcdResults(results) {
    return _.map(results, result => {
      const { value, info, description } = result;
      var text = `${value} - ${info}`;
      const id = `[${value}] ${info}`;

      return { id, text };
    });
  }

  get formSelector() {
    return `#add_diagnosis_${this.icdType}_form`;
  }

  bindAddForm() {
    const addPanel = new CaseAddPanel({
      areaSelector: '',
      afterSave: (response) => {
        const json = JSON.parse(response);
        $j('#diagnoses_area').html(json['diagnoses_area']);
        $j('.chosen-concepts').append(json['new_chosen_concept']);
        refreshProblemsArea();
      },
      errorSelector: `#add_${this.icdType}_diagnosis_errors`,
      formSelector: this.formSelector
    });

    addPanel.bindForm();

    this.bindKeyboardEvents(addPanel);
  }

  bindKeyboardEvents(casePanel) {
    addSaveShortcut(this.formSelector, e => { casePanel.submitForm(e) });
  }
}
