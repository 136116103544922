import { Controller } from 'stimulus'

class DrugListsController extends Controller {
  static targets = [
    'inactiveDrugList',
    'inactiveDrugListsCheckbox',
    'drugListTableRow'
  ]
  initialize() {}
  connect() {
    var inactiveDrugListsChecked = sessionStorage.getItem('inactive_drug_lists_visible')
    if(inactiveDrugListsChecked == 1) {
      this.inactiveDrugListsCheckboxTarget.checked = true
    }
    this.toggleLists()
  }

  toggleLists() {
    this.toggleHiddenRows()
    this.alternateRowColors()
  }

  toggleHiddenRows() {
    if(this.inactiveDrugListsCheckboxTarget.checked) {
      sessionStorage.setItem('inactive_drug_lists_visible', 1)
      this.inactiveDrugListTargets.forEach(target => {
        target.classList.remove('hidden')
      })
    } else {
      sessionStorage.setItem('inactive_drug_lists_visible', 0)
      this.inactiveDrugListTargets.forEach(target => {
        target.classList.add('hidden')
      })
    }
  }

  alternateRowColors() {
    const visible_rows = this.drugListTableRowTargets.filter(target => {
      return !target.classList.contains('hidden')
    })

    visible_rows.forEach((target, i) => {
      if (i % 2 == 1) {
        target.classList.add('alternate')
      }
      else {
        target.classList.remove('alternate')
      }
    })
  }
}
export default DrugListsController