class ElectronicSignatures {
  attach() {
    this.toggle();

    $j("#practice_use_electronic_signature")
      .on('change', this.toggle.bind(this));
    $j('#practice_use_auto_signature')
      .on('change', this.toggleAutoSignatureField.bind(this));
    $j('#practice_exclude_q_products_from_electronic_signature')
      .on('change', this.toggleExcludeQProductsField.bind(this));
    $j('#practice_allow_proxy_signature')
      .on('change', this.toggleAllowProxySignature.bind(this));
  }
  toggle() {
    this.toggleAssistSignatureField();
    this.toggleAutoSignatureField();
    this.toggleExcludeQProductsField();
    this.toggleAllowProxySignature();
  }

  toggleAutoSignatureField() {
    var autoSignature = $j('#practice_use_auto_signature');
    this.toggleElectronicSubItemField(autoSignature);
  }

  toggleAssistSignatureField() {
    var assistSignature = $j('#practice_use_assist_signature');
    this.toggleElectronicSubItemField(assistSignature);
  }

  toggleExcludeQProductsField() {
    var excludeQProducts =
      $j('#practice_exclude_q_products_from_electronic_signature');
    this.toggleElectronicSubItemField(excludeQProducts);
  }

  toggleAllowProxySignature() {
    var allowProxySignature = $j('#practice_allow_proxy_signature');
    this.toggleElectronicSubItemField(allowProxySignature);
  }

  toggleElectronicSubItemField(subItemField) {
    var electronicSignature = $j('#practice_use_electronic_signature');
    if (electronicSignature.is(':checked')) {
      subItemField.prop('disabled', false);
    } else {
      subItemField.prop('checked', false);
      subItemField.prop('disabled', true);
    }
  }
}

class RemediationQueue {
  attach() {
    var self = this;

    this.toggle();

    $j("#practice_remediation_queue_enabled")
      .on('change', this.toggle.bind(this));
    $j('#practice_rtpd_enabled')
      .on('change', this.toggleRTPField.bind(this));
  }

  toggle() {
    this.toggleRTPField();
  }

  toggleRTPField() {
    var rtpEnabled = $j('#practice_rtp_enabled');
    this.toggleSubItem(rtpEnabled);
  }

  toggleSubItem(subItemField) {
    var remediationQueueEnabled = $j('#practice_remediation_queue_enabled');
    if (remediationQueueEnabled.is(':checked')) {
      subItemField.prop('disabled', false);
    } else {
      subItemField.prop('checked', false);
      subItemField.prop('disabled', true);
    }
  }
}

export default class EditPractice {
  bindForm() {
    var electronicSignatures = new ElectronicSignatures();
    var remediationQueue = new RemediationQueue();
    electronicSignatures.attach();
    remediationQueue.attach();
  }
}
