import { setCSRFHeaders } from '~/javascript/utilities/ajax_helpers';

export default class BatchReview {
  markReviewedClicked(e) {
    e.preventDefault();

    $j.ajax({
      beforeSend: setCSRFHeaders,
      method: 'PUT',
      url: e.target.href,
      success: (redirectUrl) => {
        window.location.href = redirectUrl;
      }
    })
  }

  batchFlagChanged(e) {
    const form = $j("#batch_flags");
    const action = form.attr('action');

    $j.ajax({
      url: action,
      beforeSend: setCSRFHeaders,
      method: 'PUT',
      data: form.serialize()
    });
  }

  // We intentionally keep the remote checkboxes disabled until the DOM is ready
  // with its AJAX bindings.
  enableRemoteCheckboxes() {
    $j("#reviewed").attr('disabled', false);
    $j("#has_errors").attr('disabled', false);
  }

  bindForm() {
    this.enableRemoteCheckboxes();

    $j("#mark_reviewed").on('click', this.markReviewedClicked);
    $j('#batch_flags input[type=checkbox]').on('change', this.batchFlagChanged);
  }
};


