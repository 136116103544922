import { Controller } from "stimulus"

class DragPositionController extends Controller {
  static targets = ['dragArea', 'dragRowActive', 'dragRowInactive', 'showHideToggle']

  // Lifecycle Callbacks
  initialize() {}
  connect() {}
  disconnect() {}

  // when the dragArea target is connected 
  // process this event.  In this instance 
  // it is after the turbo-frame has rendered.
  dragAreaTargetConnected() {
    this.elementId = this.element.className
    var sessionEnabled = sessionStorage.getItem(this.elementId)
    if(this.hasShowHideToggleTarget) {
      if(sessionEnabled == 1) {
        this.showHideToggleTarget.checked = true
      } else {
        this.showHideToggleTarget.checked = false
      }
    }
    this.toggleHiddenInactive()
  }

  // Drag Events
  dragStart(event) {
    this.draggedItem = event.target
    event.target.classList.add('drag-over')
  }

  dragOver(event) {
    event.preventDefault()
    return false
  }

  dragEnter(event) {
    event.preventDefault()
    return false
  }

  drop(event) {
    event.stopPropagation();
    var dragged_id = this.draggedItem.dataset.id
    var new_position = event.currentTarget.dataset.position
    var post_url = this.element.dataset.url
    var csrf_token = document.head.querySelector("[name='csrf-token']").content

    fetch(post_url, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        Accept: 'text/vnd.turbo-stream.html',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf_token,
      },
      body: JSON.stringify({
        id: dragged_id,
        position: new_position,
      })
    })
      .then((response) => response.text())
      .then(html => Turbo.renderStreamMessage(html))
  }

  dragEnd(event) {
    event.preventDefault()
    event.target.classList.remove('drag-over')
  }

  toggleHiddenInactive() {
    if(this.hasShowHideToggleTarget) {
      if(this.showHideToggleTarget.checked) {
        sessionStorage.setItem(this.elementId, 1)
        this.showInactive()
      } else {
        sessionStorage.setItem(this.elementId, 0)
        this.hideInactive()
      }
    }
  }

  hideInactive() {
    this.dragRowInactiveTargets.forEach(target => {
      target.style.display = 'none'
    })
  }

  showInactive() {
    this.dragRowInactiveTargets.forEach(target => {
      target.style.display = 'table-row'
    })
  }
}

export default DragPositionController
