import { Controller } from 'stimulus'

class OidcProvidersController extends Controller {
  static targets = [
    'inactiveProvider',
    'inactiveProvidersCheckbox'
  ]
  initialize() {}
  connect() {
    var inactiveProvidersChecked = sessionStorage.getItem('inactive_providers_visible')
    if(inactiveProvidersChecked == 1) {
      this.inactiveProvidersCheckboxTarget.checked = true
      this.toggleProviders()
    }
  }

  toggleProviders() {
    if(this.inactiveProvidersCheckboxTarget.checked) {
      sessionStorage.setItem('inactive_providers_visible', 1)
      this.inactiveProviderTargets.forEach(target => {
        target.classList.remove('hidden')
      })
    } else {
      sessionStorage.setItem('inactive_providers_visible', 0)
      this.inactiveProviderTargets.forEach(target => {
        target.classList.add('hidden')
      })
    }
  }
}
export default OidcProvidersController