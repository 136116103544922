import { SubmitButton } from '~/javascript/buttons';
import { render as renderClinicalCompletionInfo } from '~/javascript/cases/clinical_case_completion_info';

const CaseInfoForm = {
  submit: function(e) {
    e.preventDefault();

    $j('#case_info input.submit.button').each(SubmitButton.disable);

    const { method, action } = e.target;

    $j.ajax({
      url: action,
      method: method,
      data: $j(e.target).serialize(),
      success: (response) => {
        $j('#case_info').html(response);
        $j('input#case_weight_in_location_units').on('keyup', handleWeightInputEvent);
        $j('input#case_height_in_location_units').on('keyup', handleHeightInputEvent);
        FlashMessage.remove();
        renderClinicalCompletionInfo();
      },
      error: (response) => {
        FlashMessage.insert(response.responseText, 'error', 'error');
      },
      complete: () => {
        $j('#case_info input.submit.button').each(SubmitButton.enable);
      }
    });
  }
};

export default CaseInfoForm;
