export default class RaceForm {
  shouldHideSubraceSelect(raceSelect) {
    if(raceSelect.selectedIndex == -1) return true;

    var selectedOptions = raceSelect.options[raceSelect.selectedIndex]
    return (selectedOptions.text === "" || selectedOptions.text.toLowerCase() === "declined to specify");
  };

  selectedRaces(raceSelect) {
    return $j.map($j(raceSelect).find("option:selected"),
      function(x) { return x.value }).join(",")
  };

  selectedSubraceValues() {
    var defaults = $j("#subrace_default_values").text();
    return defaults ? defaults.split(",") : [];
  };

  mapRaceResultsToSelectFormat(response) {
    return $j.map(response.races, function(parentRace) {
      return {
        text: parentRace.name,
        children: $j.map(parentRace.subraces, function(subrace) {
          return { id: subrace.id, text: subrace.name }
        })
      };
    });
  }

  onRaceSelect(raceSelect, selectedSubraces) {
    var _this = this;

    if(_this.shouldHideSubraceSelect(raceSelect)) {
      $j("#subraces_container").hide();
    }
    else {
      $j("#subraces_container").show();
      var parentRaceConceptIds = _this.selectedRaces(raceSelect);

      $j.getJSON('/races/' + parentRaceConceptIds, null, function(response) {
        var data = _this.mapRaceResultsToSelectFormat(response);

        $j("#case_subrace_ids").empty();
        $j("#case_subrace_ids").select2({ data: data, multiple: true, width: 400, val: selectedSubraces });

        if(selectedSubraces.length > 0) {
          $j("#case_subrace_ids").val(selectedSubraces);
          $j("#case_subrace_ids").trigger('change');
        }
      });
    }
  }

  bind() {
    var _this = this;

    $j("#case_race_ids").change(function(e) {
      _this.onRaceSelect(this, e);
    });

    $j("#race_ids").on('click', function() {
      _this.onRaceSelect($j("#case_race_ids")[0], _this.selectedSubraceValues());
    });
  };
}