var Selectable = {
  mouseover:function() { $j(this).addClass('highlight'); },
  mouseout:function() { $j(this).removeClass('highlight'); }
};

var SelectableOption = {
  click:function(e) {
    var node = $j((this.parentNode.id) ? this.parentNode : this);
    if (node.attr('id')) {
      var editNode = $j(`#edit_${node.attr('id')}`);
      if (editNode) {
        if (editNode.is(':visible')) {
          SelectableOption.close(node.attr('id'));
        } else {
          // close other edit nodes that may still be open
          $j('#case_info .inline-edit').each(function(index, el) {
            var id = el.id.replace('edit_', '');
            SelectableOption.close(id);
          });
          editNode.show();
          node.addClass('editing');
          editNode.prev().addClass('edit_panel_open');
        }
      }
    }
    return false;
  },
  close:function(nodeId) {
    var node = $j(`#${nodeId}`);
    var editNode = $j(`#edit_${nodeId}`);
    editNode.hide();
    node.removeClass('editing');
    editNode.prev().removeClass('edit_panel_open');
    editNode.closest('form').trigger('reset');
  }
}

export { Selectable, SelectableOption };