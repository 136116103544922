import { DeleteButton, LinkButton } from '~/javascript/buttons';
import { setCSRFHeaders } from '~/javascript/utilities/ajax_helpers';
import { render as renderClinicalCompletionInfo } from '~/javascript/cases/clinical_case_completion_info';

export default class CaseEditPanel {
  constructor(options) {
    this.objectId = options.objectId;
    this.formSelector = options.formSelector;
    this.areaSelector = options.areaSelector;
    this.errorSelector = options.errorSelector;
    this.deleteButtonSelector = options.deleteButtonSelector;
    this.afterUpdate = options.afterUpdate || null;
    this.afterDelete = options.afterDelete || null;
  }

  bindForm() {
    this.bindEditSubmit();
    this.bindDeleteSubmit();
  }

  bindEditSubmit() {
    $j(this.formSelector).on('submit', e => {
      e.preventDefault();

      LinkButton.disableElementsIn(this.formSelector, 'Saving...');

      const { action, method } = e.target;

      $j.ajax({
        url: action,
        method: method,
        data: $j(e.target).serialize(),
        success: (response) => {

          if(this.afterUpdate) {
            this.afterUpdate(response);
          } else {
            $j(this.areaSelector).html(response);
          }
        },
        error: (response) => {
          $j(this.errorSelector).html(response.responseText);
        },
        complete: () => {
          LinkButton.enableElementsIn(this.formSelector, 'Save');
        }
      });
    });
  }

  bindDeleteSubmit() {
    $j(this.deleteButtonSelector).on('click', (e) => {
      const deleteButton = e.target;
      e.preventDefault();

      const confirmMsg = deleteButton.dataset.confirmMsg;

      if(!confirm(confirmMsg)) {
        return;
      }

      DeleteButton.disable(deleteButton);

      $j.ajax({
        method: 'delete',
        url: e.target.href,
        beforeSend: setCSRFHeaders,
        success: (response) => {
          if(this.afterDelete) {
            this.afterDelete(response);
          } else {
            $j(this.areaSelector).html(response);
          }
          renderClinicalCompletionInfo();
        },
        error: (response) => {
          $j(this.errorSelector).html(response.responseText);
        },
        complete: () => {
          DeleteButton.enable(deleteButton);
        }
      });
    });
  }
}
