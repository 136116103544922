import CaseEditPanel from '~/javascript/cases/case_panels/case_edit_panel';
import { bindBillingCodeSelect, bindModifierSelect } from '~/javascript/cases/billing_codes/billing_code_controls';

export default class EditBillingCodeForm {
  constructor(options) {
    this.billingCodeAutocompleteUrl = options.billingCodeAutocompleteUrl;
    this.modifiersAutocompleteUrl = options.modifiersAutocompleteUrl;
    this.billingCodeId = options.billingCodeId;
  }

  bindForm() {
    const editPanel = new CaseEditPanel({
      areaSelector: '#billing_codes_area',
      errorSelector: `#edit_billing_code_${this.billingCodeId}_errors`,
      deleteButtonSelector: `#delete_billing_code_${this.billingCodeId}_button`,
      formSelector: `#edit_billing_code_${this.billingCodeId}_form`,
      objectId: this.billingCodeId
    })
    editPanel.bindForm();

    bindModifierSelect({
      selector: `#first_modifier_field_for_coded_value_${this.billingCodeId}`,
      autocompleteUrl: this.modifiersAutocompleteUrl
    });

    bindModifierSelect({
      selector: `#second_modifier_field_for_coded_value_${this.billingCodeId}`,
      autocompleteUrl: this.modifiersAutocompleteUrl
    });
  }
}
