import CaseAddPanel from '~/javascript/cases/case_panels/case_add_panel';

export default class AddEventForm {
  bindForm() {
    const addForm = new CaseAddPanel({
      afterSave: (response) => {
        var json = JSON.parse(response);
        $j('#events_area').html(json['events']);
        $j('#assignments_area').html(json['providers']);
      },
      areaSelector: '#events_area',
      errorSelector: '#add_event_errors',
      formSelector: "#add_event_form"
    });

    addForm.bindForm();
  }
}
