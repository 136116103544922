import imagePath from '~/images/spinner.gif';

import '~/stylesheets/spinner.css';

export const showSpinner = (selector, options = {}) => {
  $j(selector).empty();

  $j(selector).html(`
    <div class="loading-spinner">
      <img src="${imagePath}" alt="${options.loadingMessage || 'Loading' }" />
    </div>
  `);
};

export const hideSpinner = (selector) => {
  $j(selector).find('div.loading-spinner').empty();
};
