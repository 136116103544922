import _ from 'underscore';
import { processResults } from '~/javascript/utilities/autocompleter';

const formatBillingCodeResults = (results) => {
  return _.map(results, result => {
   const { code, name } = result;
    var id = `[${code}] ${name}`;
    var text = `${code} - ${name}`;

    return { id, text };
  });
};

export const bindBillingCodeSelect = ( { selector, autocompleteUrl }) => {
  $j(selector).select2({
    width: 400,
    minimumInputLength: 1,
    ajax: {
      url: autocompleteUrl,
      delay: 250,
      data: (params) => {
        return {
          code: params.term
        };
      },
      dataType: 'json',
      processResults: (data, params) => {
        return processResults(data, params, formatBillingCodeResults);
      }
    }
  });
};

export const bindModifierSelect = ( { selector, autocompleteUrl }) => {
  $j(selector).select2({
    width: 400,
    minimumInputLength: 1,
    ajax: {
      url: autocompleteUrl,
      delay: 250,
      data: (params) => {
        return {
          code: params.term
        };
      },
      dataType: 'json',
      processResults: (data, params) => {
        return processResults(data, params, formatBillingCodeResults);
      }
    }
  });
};
