class AuditLog {
  toggle(path) {
    var button = $j('#audit_log_button');
    $j('#audit_log').toggle(); // toggle visibility of audits

    if ($j('#audit_log').find('table.audits').length === 0) { // audits have not yet been loaded into the page
      $j.get(path).done(response => {
        $j('#audit_log').html(response);
      }).fail(() => {
       $j('#audit_log').html('Unable to load changes. Please contact an admin.');
      }).always(() => {
       button.html('Hide Log &uarr;');
      });
    } else {
      // update button text to match visibility of audits
      button.html($j('#audit_log').is(":visible") ? 'Hide Log &uarr;' : 'Show Log &darr;');
    }
    return false;
  }
}

export default AuditLog;