import { setCSRFHeaders } from '~/javascript/utilities/ajax_helpers';

export default class CorrectCaseForm {
  bindForm() {
    $j("#correct_abandoned_case_form").on('submit', e => {
      e.preventDefault();

      CorrectAbandonedCaseSlider.disable_save_button_with('Correcting...');

      const { method, action } = e.target;

      $j.ajax({
        beforeSend: setCSRFHeaders,
        url: action,
        method: method,
        data: $j(e.target).serialize(),
        success: (response) => {
          window.location = response;
        },
        error: (response) => {
          CorrectAbandonedCaseSlider.error(response);
        },
        complete: (response) => {
          CorrectAbandonedCaseSlider.enable_save_button();
        }
      });
    });
  }
}
