class LinkToggle {
  bindLinks() {
    $j(() => {
      $j(document).on('click', '.toggle .buttons .yes-button', this.clickYes.bind(this));
      $j(document).on('click', '.toggle .buttons .no-button', this.clickNo.bind(this));
    });
  }

  click(element, state) {
    const parent = $j(element.target).closest('div');
    const url = parent.data('url');
    const csrf = $j('meta[name="csrf-token"]').attr('content');

    $j.ajax({
      url: url,
      method: 'PUT',
      data: {
        value: state
      },
      beforeSend: function(xhr) { xhr.setRequestHeader('X-CSRF-Token', csrf ) },
    });

    parent.removeClass('no');
    parent.removeClass('yes');
    parent.addClass(state);
  }

  clickYes(element) {
    this.click(element, 'yes');
  }

  clickNo(element) {
    this.click(element, 'no');
  }
}

export default LinkToggle;
