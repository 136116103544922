import date_time_picker from '~/javascript/date_time_picker';

class TodayAddCaseForm {
  bindForm() {
    date_time_picker('#date_of_birth_wrapper', {
      maxDate: new Date(),
      disableNow: true,
    });

    $j('#range_filter_select').on('change', function () {
      $j('#range_filter_form').submit();
    });

    const formEl = $j('#new_case');
    formEl.on('submit', function (event) {
      event.preventDefault();
      const form = $j(this);
      const actionUrl = form.attr('action');
      const submitButton = form.find(':submit');
      submitButton.prop('value', 'Adding...');
      submitButton.prop('disabled', true);

      $j.ajax({
        type: 'POST',
        url: actionUrl,
        data: form.serialize(),
        success: function (_event, _data, xhr) {
          window.location = xhr.responseText;
        },
        error: function (e) {
          FlashMessage.insert(e.responseText, 'error', 'error');
          submitButton.prop('value', 'Add Case');
          submitButton.prop('disabled', false);
        },
      });
    });
  }
}

export default TodayAddCaseForm;
