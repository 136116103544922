import date_time_picker from '~/javascript/date_time_picker';

jQuery(document).ready(() => {
  date_time_picker('#from_date_wrapper', {
    batchFormat: true,
    maxDate: new Date(),
    disableNow: true,
  });

  date_time_picker('#to_date_wrapper', {
    defaultDate: new Date(),
    batchFormat: true,
    maxDate: new Date(),
    disableNow: true,
  });

  $j('input#enable_range').on('click', function() {
    var label = 'Date'
    if (this.checked) {
      label = 'From Date'
      $j('div#range_to_date').show();
      $j('input#submit_field').hide();
    } else {
      $j('div#range_to_date').hide();
      $j('input#view_type').val('web');
      $j('input#submit_field').show();
    }
    $j('label#from_date_label').text(label);
  });

  function submitForm(e) {
    e.preventDefault()
    var form = $j('form#new_contract_utilization_filter');
    var targetType = e.target.id
    $j('input#view_type').val(targetType);

    var windowTarget = ((targetType == 'standalone') ? '_blank' : '_self')
    form.prop('target', windowTarget)
    form.submit()
    $j('input#view_type').val('web');
  }

  $j('a#export').on('click', submitForm);
  $j('a#standalone').on('click', submitForm);
});
