import { DeleteButton } from "~/javascript/buttons";
import { setCSRFHeaders } from '~/javascript/utilities/ajax_helpers';
import CaseEditPanel from "~/javascript/cases/case_panels/case_edit_panel";
import { refreshProblemsArea } from "~/javascript/cases/problems/problem_panel";

export default class EditDiagnosisForm {
  constructor({ diagnosisId, deleteButtonId }) {
    this.diagnosisId = diagnosisId;
    this.deleteButtonId = deleteButtonId;
  }

  bindForm() {
    const editPanel = new CaseEditPanel({
      afterDelete: (response) => {
        const json = JSON.parse(response);
        $j('#diagnoses_area').html(json['diagnoses_area']);
        $j('#procedures_area').html(json['procedures_area']);
        $j('#billing_codes_area').html(json['billing_codes_area']);
        refreshProblemsArea();
      },
      areaSelector: '#diagnoses_area',
      deleteButtonSelector: this.deleteButtonId,
      errorSelector: `#edit_diagnosis_${this.diagnosisId}_errors`,
      formSelector: `#edit_diagnosis_${this.diagnosisId}_errors`,
      objectId: this.diagnosisId
    });

    editPanel.bindForm();
  }
}
